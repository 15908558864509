import React, { useState, useEffect, useCallback } from 'react';
import ReactFlow, { MiniMap, Controls, Background, Handle, useNodesState, useEdgesState, addEdge, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import './FlowManager.css';
import axios from 'axios';
import LikeIcon from './assets/icons/like.png';
import DislikeIcon from './assets/icons/dislike.png';
import CommentManager from './CommentManager'; // Importa il componente dei commenti
import NodeList from './NodeList'; // Importa il componente NodeList

// Funzione per sincronizzare likes e dislikes dal database
const syncLikesAndDislikes = async (rectangles, setNodes) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles`);
    const updatedRectangles = response.data;

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        const updatedRectangle = updatedRectangles.find((rect) => rect.rectangle_id === node.id);
        if (updatedRectangle) {
          return {
            ...node,
            data: {
              ...node.data,
              likes: updatedRectangle.likes || 0,
              dislikes: updatedRectangle.dislikes || 0,
            },
          };
        }
        return node;
      })
    );
  } catch (error) {
    console.error('Errore durante la sincronizzazione dei likes e dislikes:', error);
  }
};

const CustomNode = ({ data }) => {
  return (
    <div
      className={`custom-node ${data.isFirstNode ? 'highlighted-node' : ''}`}
      onClick={data.onClick}
      style={{
        width: '200px',
        height: '80px',
        padding: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '5px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'relative',
        fontFamily: 'SF Mono, monospace',
        textAlign: 'center',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      <div className="icon-container">
        <button
          className="custom-like-button"
          onClick={(e) => data.onLike(e, 'like')}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            fontSize: '15px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={LikeIcon} alt="Like" style={{ width: '25px', height: '20px' }} />
          <span style={{ fontSize: '12px', color: '#2EFF17' }}>{data.likes}</span>
        </button>
        <button
          className="custom-dislike-button"
          onClick={(e) => data.onLike(e, 'dislike')}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: 0,
            fontSize: '15px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={DislikeIcon} alt="Dislike" style={{ width: '25px', height: '20px' }} />
          <span style={{ fontSize: '12px', color: '#FF6347' }}>{data.dislikes}</span>
        </button>
      </div>
      <div style={{ marginTop: '10px', fontSize: '12px' }}>{data.label}</div>
      <button
        className="comment-button"
        onClick={(e) => data.onComment(e)}
        style={{
          marginTop: '10px',
          backgroundColor: '#333',
          color: '#fff',
          border: 'none',
          padding: '5px 10px',
          cursor: 'pointer',
        }}
      >
        Commenti
      </button>
      <Handle type="target" position="top" />
      <Handle type="source" position="bottom" />
    </div>
  );
};

// Definizione dei tipi di nodo
const nodeTypes = {
  custom: CustomNode,
};

const FlowManager = ({ rectangles, handleRectangleClick }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedRectangleId, setSelectedRectangleId] = useState(null); // ID del rettangolo selezionato
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false); // Stato della modale dei commenti

  // Funzione per gestire i like/dislike
  const handleLike = useCallback(
    async (e, type, rectangle) => {
      e.stopPropagation();
      try {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
          throw new Error("Token mancante. L'utente non è autenticato.");
        }

        // Invia la richiesta al backend per aggiornare il numero di like o dislike
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles/update-reaction`,
          { rectangle_id: rectangle.rectangle_id, type },
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );

        // Sincronizza i likes e dislikes dopo l'aggiornamento
        syncLikesAndDislikes(rectangles, setNodes);
      } catch (error) {
        console.error(`Errore durante l'aggiornamento del ${type}:`, error);
      }
    },
    [rectangles, setNodes]
  );

  // Funzione per gestire l'apertura della modale dei commenti
  const handleCommentClick = (e, rectangleId) => {
    e.stopPropagation();
    setSelectedRectangleId(rectangleId);
    setIsCommentModalOpen(true);
  };

  // Funzione per navigare a un nodo specifico
  const handleNodeSelect = (nodeId) => {
    if (nodeId === null) {
      // Se null, non centrare nessun nodo
      return;
    }
    const node = nodes.find((n) => n.id === nodeId);
    if (node && ReactFlowInstance) {
      // Pan e zoom verso il nodo selezionato
      ReactFlowInstance.fitView({
        nodes: [node],
        padding: 0.2,
        duration: 800,
      });
    }
  };

  // Stato per gestire l'istanza di ReactFlow
  const [ReactFlowInstance, setReactFlowInstance] = useState(null);

  // Aggiorna nodi e edges quando i dati dei rettangoli cambiano
  useEffect(() => {
    const newNodes = rectangles.map((rect, index) => {
      // Logica di posizionamento: il primo nodo è sopra il centro
      const radius = 200;
      const angle = (index - 1) * (360 / (rectangles.length - 1));
      const position =
        index === 0
          ? { x: 250, y: 50 }
          : {
              x: 250 + radius * Math.cos(angle * (Math.PI / 180)),
              y: 150 + radius * Math.sin(angle * (Math.PI / 180)),
            };

      const label = `${new Date(rect.createdAt).toLocaleString('it-IT')} - ${rect.username || 'Utente sconosciuto'}`;

      return {
        id: rect.rectangle_id,
        type: 'custom',
        data: {
          label,
          onClick: () => handleRectangleClick(rect),
          onLike: (e, type) => handleLike(e, type, rect),
          onComment: (e) => handleCommentClick(e, rect.rectangle_id), // Gestione click su commenti
          isFirstNode: index === 0,
          likes: rect.likes || 0,
          dislikes: rect.dislikes || 0,
        },
        position,
        draggable: index !== 0,
      };
    });

    const newEdges = rectangles.slice(1).map((rect, index) => ({
      id: `e${rectangles[index].rectangle_id}-${rect.rectangle_id}`,
      source: rectangles[index].rectangle_id,
      target: rect.rectangle_id,
      animated: true,
      style: { stroke: 'green' },
    }));

    setNodes(newNodes);
    setEdges(newEdges);

    // Sincronizza i likes e dislikes dopo il caricamento iniziale
    syncLikesAndDislikes(rectangles, setNodes);
  }, [rectangles, handleRectangleClick, handleLike, setEdges, setNodes]);

  // Gestione delle nuove connessioni tra nodi
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  return (
    <div className="flow-manager-wrapper">
      {/* Lista dei nodi */}
      <NodeList nodes={rectangles} onNodeSelect={handleNodeSelect} />

      <div
        className="react-flow-wrapper"
        style={{ height: '100%', width: '100%', touchAction: 'manipulation' }}
      >
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            fitView
            panOnScroll={false} // Disabilita il pan con lo scroll
            panOnDrag={true} // Consente di trascinare il canvas con il drag
            zoomOnScroll={true} // Abilita lo zoom con la rotellina del mouse
            zoomOnPinch={true} // Abilita lo zoom con il pinch (per dispositivi touch)
            zoomOnDoubleClick={true} // Abilita lo zoom con doppio click
            onInit={setReactFlowInstance} // Imposta l'istanza di ReactFlow
          >
            <MiniMap />
            <Controls />
            <Background color="#555" gap={16} />
          </ReactFlow>
        </ReactFlowProvider>

        {/* Modale CommentManager per gestire i commenti */}
        {selectedRectangleId && (
          <CommentManager
            rectangleId={selectedRectangleId}
            isOpen={isCommentModalOpen}
            onClose={() => setIsCommentModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default FlowManager;
