import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
import './AchievementsPage.css';

const AchievementsPage = () => {
  const [achievements, setAchievements] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('Token non trovato. Effettua il login.');
          return;
        }

        const decodedToken = jwtDecode(token); // Using jwtDecode correctly
        const userId = decodedToken.userId;

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/achievements`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log('Dati achievement ricevuti:', response.data);
        setAchievements(response.data);
      } catch (error) {
        console.error('Errore nel recupero degli achievement:', error.message);
        setError('Errore nel recupero degli achievement.');
      }
    };

    fetchAchievements();
  }, []);

  return (
    <div className="achievements-container">
      <h1>Achievements</h1>
      {error && <p className="error-message">{error}</p>}
      <div className="achievements-list">
        {achievements.length > 0 ? (
          achievements.map((achievement) => (
            <div key={achievement.id} className="achievement-item">
              <img
                src={`/images/achievements/${achievement.name.toLowerCase().replace(/ /g, '_')}.png`}
                alt={achievement.name}
                className="achievement-icon"
              />
              <div className="achievement-details">
                <h3>{achievement.name}</h3>
                <p>{achievement.description}</p>
                <p className="achievement-lore">{achievement.lore_description}</p>
              </div>
            </div>
          ))
        ) : (
          <p>Nessun achievement sbloccato</p>
        )}
      </div>
    </div>
  );
};

export default AchievementsPage;