import React, { useState } from 'react';

// Componente per visualizzare e interagire con l'elenco dei nodi
const NodeList = ({ nodes, onNodeSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false); // Stato per controllare l'espansione

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Inverte lo stato di espansione
  };

  return (
    <div className={`node-list-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
      {/* Linguetta cliccabile per espandere o comprimere */}
      <div className="node-tab" onClick={toggleExpand}>
        {isExpanded ? '▲ Nodi' : '▼ Nodi'}
      </div>

      {/* Elenco dei nodi visibile solo se isExpanded è true */}
      {isExpanded && (
        <div className="node-list">
          {nodes.length > 0 ? (
            nodes.map((node) => (
              <div
                key={node.rectangle_id}
                onClick={() => onNodeSelect(node.rectangle_id)}
                className="node-item"
              >
                {node.createdAt
                  ? `${new Date(node.createdAt).toLocaleString('it-IT')} - ${node.username || 'Utente sconosciuto'}`
                  : 'Data non disponibile - Utente sconosciuto'}
              </div>
            ))
          ) : (
            <div className="no-nodes">Nessun nodo disponibile</div>
          )}
        </div>
      )}
    </div>
  );
};

export default NodeList;
