import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';


// Funzione per creare una struttura annidata di commenti
const nestComments = (comments) => {
  const commentMap = {};
  const nestedComments = [];

  comments.forEach((comment) => {
    commentMap[comment.comment_id] = { ...comment, replies: [] };
  });

  comments.forEach((comment) => {
    if (comment.parent_comment_id) {
      commentMap[comment.parent_comment_id].replies.push(commentMap[comment.comment_id]);
    } else {
      nestedComments.push(commentMap[comment.comment_id]);
    }
  });

  return nestedComments;
};

// Modale per i commenti
const CommentManager = ({ rectangleId, isOpen, onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);

  // Funzione per caricare tutti i commenti e le risposte per un rettangolo
  const fetchAllComments = async () => {
    if (!rectangleId) return;
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/unique-comments/${rectangleId}/null/100`);
      if (response.data) {
        const nestedComments = nestComments(response.data); // Crea la struttura annidata dei commenti
        setComments(nestedComments);
      }
    } catch (error) {
      console.error('Errore durante il caricamento dei commenti:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && rectangleId) {
      fetchAllComments(); // Carica tutti i commenti e le risposte
    }
  }, [isOpen, rectangleId]);

  // Funzione per gestire l'aggiunta di un nuovo commento
  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/unique-comments`, {
        rectangle_id: rectangleId,
        content: newComment,
        user_id: localStorage.getItem('userId'),
        parent_comment_id: currentParentId ? currentParentId : null,
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      // Aggiungi il nuovo commento o risposta alla struttura annidata
      if (currentParentId) {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.comment_id === currentParentId ? 
              { ...comment, replies: [...(comment.replies || []), response.data] } : comment
          )
        );
      } else {
        setComments((prevComments) => [...prevComments, { ...response.data, replies: [] }]);
      }

      setNewComment('');
      setCurrentParentId(null);
    } catch (error) {
      console.error('Errore durante l\'inserimento del commento:', error);
    } finally {
      setLoading(false);
    }
  };

  // Funzione per gestire la risposta a un commento
  const handleReply = (commentId) => {
    setCurrentParentId(commentId);
    setNewComment(`@${commentId} `); // Placeholder per identificare la risposta
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="rect-comm-modal" overlayClassName="rect-comm-overlay">
      <h2>Commenti</h2>
      <div className="comments-container">
        {comments.map((comment) => (
          <div key={comment.comment_id} className="comment">
            <p>{comment.content}</p>
            <div className="replies-container">
              {comment.replies.map((reply) => (
                <div key={reply.comment_id} className="reply">
                  <p><strong>Risposta:</strong> {reply.content}</p>
                </div>
              ))}
            </div>
            <button onClick={() => handleReply(comment.comment_id)}>Rispondi</button>
          </div>
        ))}
        {loading && <p>Caricamento...</p>}
      </div>
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Scrivi un commento..."
        className="comment-textarea"
      />
      <div className="comment-actions">
        <button onClick={handleAddComment} disabled={loading || !newComment.trim()}>Invia</button>
        <button onClick={onClose} disabled={loading}>Chiudi</button>
        <button onClick={fetchAllComments} disabled={loading}>Ricarica Commenti</button>
      </div>
    </Modal>
  );
};

// Impostazioni di default per la modale, in modo da non interferire con altre modali
Modal.defaultStyles.overlay.zIndex = '1000'; // Imposta un valore di z-index elevato

export default CommentManager;
