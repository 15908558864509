// src/ModalManager.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import './ModalManager.css'; // Stile CSS per la modale

// Imposta l'URL base dell'API
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it';
console.log('API_BASE_URL:', API_BASE_URL); // Log per il debug

/**
 * Funzione per inviare l'email di verifica
 */
const handleSendVerificationEmail = async (userId, email) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/send-verification-email`, { userId, email });
    if (response.status === 200) {
      return 'Email di verifica inviata! Controlla la tua casella di posta.';
    } else {
      throw new Error('Errore durante l\'invio dell\'email di verifica.');
    }
  } catch (error) {
    console.error('Errore durante l\'invio dell\'email di verifica:', error.message);
    return 'Errore durante l\'invio dell\'email di verifica.';
  }
};

/**
 * Funzione per gestire la registrazione
 */
const handleRegister = async (e, registerData, setMessage, setVerificationMessage) => {
  e.preventDefault();
  try {
    const response = await axios.post(`${API_BASE_URL}/api/register`, registerData);
    if (response.status === 200 && response.data.user) {
      setMessage('Registrazione avvenuta con successo! Controlla la tua email per verificare il tuo account.');

      // Invia l'email di verifica dopo la registrazione
      try {
        const verificationResponse = await handleSendVerificationEmail(response.data.user.id, registerData.email);
        setVerificationMessage(verificationResponse);
      } catch (emailError) {
        console.error('Errore durante l\'invio dell\'email di verifica:', emailError);
        setVerificationMessage('Errore durante l\'invio dell\'email di verifica. Riprova più tardi.');
      }
    } else {
      throw new Error(response.data.message || 'Errore durante la registrazione.');
    }
  } catch (error) {
    console.error('Errore durante la registrazione:', error.message);
    setMessage(error.response?.data?.message || 'Errore del server');
  }
};

/**
 * Componente ModalManager
 */
const ModalManager = ({
  modalIsOpen,
  setModalIsOpen,
  dashModalOpen,
  setDashModalOpen,
  loginModalOpen,
  setLoginModalOpen,
  registerModalOpen,
  setRegisterModalOpen,
  messageModalOpen,
  setMessageModalOpen,
  rectangleModalOpen,
  setRectangleModalOpen,
  selectedResponse,
  inizioContent,
  handleSendMessage,
  registerData,
  setRegisterData,
  userMessage,
  setUserMessage,
  isLoading,
  userInfo,
  setUserInfo,
  setIsLoggedIn,
  message,
  setMessage,
  verificationMessage,
  setVerificationMessage,
  loginData,
  setLoginData,
  userRectangles, // Aggiunto userRectangles come prop
}) => {
  const [achievements, setAchievements] = useState([]); // Stato per gli achievement
  const [passwordResetModalOpen, setPasswordResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [passwordResetMessage, setPasswordResetMessage] = useState('');

  // Funzione per gestire il login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, loginData);
      if (response.status === 200 && response.data.token) {
        localStorage.setItem('token', response.data.token);
        const decodedToken = jwt.decode(response.data.token);
        console.log('Decoded Token:', decodedToken); // Log per il debug
        if (decodedToken && decodedToken.userId) {
          localStorage.setItem('userId', decodedToken.userId);
          setIsLoggedIn(true);
          setLoginModalOpen(false);
          setMessage('Login avvenuto con successo');
        } else {
          throw new Error('Token non valido');
        }
      } else {
        throw new Error(response.data.message || 'Errore durante il login');
      }
    } catch (error) {
      console.error('Errore durante il login:', error.message);
      if (error.response && error.response.status === 403) {
        setMessage('Email non verificata. Controlla la tua casella di posta per la verifica.');
      } else {
        setMessage(error.response?.data?.message || 'Errore del server');
      }
    }
  };

  // Funzione per gestire il reset della password
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/send-reset-password`, { email: resetEmail });
      if (response.status === 200 && response.data.message) {
        setPasswordResetMessage(response.data.message);
      } else {
        throw new Error(response.data.message || 'Errore durante la richiesta di reset della password');
      }
    } catch (error) {
      console.error('Errore durante il recupero della password:', error.message);
      setPasswordResetMessage(error.response?.data?.message || 'Errore del server durante il recupero della password');
    }
  };

  // Funzione per ottenere le informazioni utente
  const fetchUserInfo = async () => {
    try {
      const storedUserId = localStorage.getItem('userId');
      const storedToken = localStorage.getItem('token');
      console.log('Fetching user info for userId:', storedUserId); // Log per il debug
      console.log('Using token:', storedToken); // Log per il debug

      const response = await axios.get(`${API_BASE_URL}/api/user/user-info`, {
        headers: {
          Authorization: `Bearer ${storedToken}`, // Invia token JWT nell'header
        },
      });

      setUserInfo(response.data); // Imposta le informazioni utente nello stato
    } catch (error) {
      console.error('Errore durante il recupero delle informazioni utente:', error.response ? error.response.data : error.message);
    }
  };

  // Funzione per recuperare gli achievement sbloccati dal backend
  const fetchAchievements = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Fetching achievements for the authenticated user'); // Log per il debug

      const response = await axios.get(`${API_BASE_URL}/api/user/achievements`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Dati achievement ricevuti:', response.data); // Log per il debug
      setAchievements(response.data); // Imposta gli achievement nello stato
    } catch (error) {
      console.error('Errore durante il recupero degli achievement:', error.response ? error.response.data : error.message);
    }
  };

  // Usa useEffect per chiamare fetchUserInfo e fetchAchievements quando la modale della Dashboard viene aperta
  useEffect(() => {
    if (dashModalOpen) {
      fetchUserInfo();
      fetchAchievements();
    }
  }, [dashModalOpen]);

  return (
    <>
      {/* Modal per L'Inizio */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="text-container">
          <h2>Inizio</h2>
          <div className="modal-text">{inizioContent}</div>
        </div>
        <button className="close-button" onClick={() => setModalIsOpen(false)}>Chiudi</button>
      </Modal>

      {/* Modal per il Login */}
      <Modal
        isOpen={loginModalOpen}
        onRequestClose={() => setLoginModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Nome utente"
            value={loginData.username}
            onChange={(e) => setLoginData({ ...loginData, username: e.target.value })}
            required
            autoComplete="username"
          />
          <input
            type="password"
            placeholder="Password"
            value={loginData.password}
            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            required
            autoComplete="current-password"
          />
          <button type="submit">Entra</button>
        </form>
        <p className="password-reset-link">
          {/* Sostituisci l'elemento <a> con un bottone stile link */}
          <button 
            type="button" 
            onClick={() => {
              setLoginModalOpen(false);
              setPasswordResetModalOpen(true);
            }}
            style={{
              background: 'none',
              border: 'none',
              color: '#007bff',
              textDecoration: 'underline',
              cursor: 'pointer',
              padding: 0,
              fontSize: 'inherit'
            }}
          >
            Password dimenticata?
          </button>
        </p>
        <p>{message}</p>
      </Modal>

      {/* Modal per il Recupero della Password */}
      <Modal
        isOpen={passwordResetModalOpen}
        onRequestClose={() => setPasswordResetModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Recupero Password</h2>
        <form onSubmit={handlePasswordReset}>
          <input
            type="email"
            placeholder="Inserisci la tua email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
          />
          <button type="submit">Invia link di recupero</button>
        </form>
        <p>{passwordResetMessage}</p>
        <button className="close-button" onClick={() => setPasswordResetModalOpen(false)}>Chiudi</button>
      </Modal>

      {/* Modal per la Registrazione */}
      <Modal
        isOpen={registerModalOpen}
        onRequestClose={() => setRegisterModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Registrazione</h2>
        <form onSubmit={(e) => handleRegister(e, registerData, setMessage, setVerificationMessage)}>
          <input
            type="text"
            placeholder="Nome utente"
            value={registerData.username}
            onChange={(e) => setRegisterData({ ...registerData, username: e.target.value })}
            required
            autoComplete="username"
          />
          <input
            type="email"
            placeholder="Email"
            value={registerData.email}
            onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
            required
            autoComplete="email"
          />
          <input
            type="password"
            placeholder="Password"
            value={registerData.password}
            onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
            required
            autoComplete="new-password"
          />
          <button type="submit">Registrati</button>
        </form>
        <p>{message}</p>
        <p>{verificationMessage}</p>
      </Modal>

      {/* Modal per l'Invio dei Messaggi */}
      <Modal
        isOpen={messageModalOpen}
        onRequestClose={() => setMessageModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Scrivi un Messaggio</h2>
        <textarea
          className="textarea-message"
          placeholder="Scrivi il tuo messaggio qui..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
        />
        <button className="send-button" onClick={handleSendMessage}>INVIA</button>
        {isLoading && <div className="loading-indicator">Elaborazione...</div>}
      </Modal>

      {/* Modal per visualizzare il contenuto selezionato del rettangolo */}
      <Modal
        isOpen={rectangleModalOpen}
        onRequestClose={() => setRectangleModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="text-container">
          <h2></h2>
          <p>{selectedResponse}</p>
        </div>
        <button className="close-button" onClick={() => setRectangleModalOpen(false)}>Chiudi</button>
      </Modal>

      {/* Modal per la Dashboard utente */}
      <Modal
        isOpen={dashModalOpen}
        onRequestClose={() => setDashModalOpen(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Dashboard</h2>
        {userInfo ? (
          <div className="user-info">
            <p><strong>Utente:</strong> {userInfo.username}</p>
            <p><strong>Email:</strong> {userInfo.email}</p>
            <p><strong>Data di Registrazione:</strong> {new Date(userInfo.registration_date).toLocaleString()}</p>
            <p><strong>Email Verificata:</strong> {userInfo.is_email_verified}</p>

            {/* Sezione Achievements */}
            <div className="user-achievements">
              <h3>Obiettivi Sbloccati</h3>
              {achievements.length > 0 ? (
                <div className="achievements-list">
                  {achievements.map((achievement, index) => (
                    <div key={index} className="achievement-item">
                      <img
                        src={`/images/achievements/${achievement.name.toLowerCase().replace(/ /g, '_')}.png`}
                        alt={achievement.name}
                        className="achievement-icon"
                      />
                      <div className="achievement-details">
                        <h4>{achievement.name}</h4>
                        <p>{achievement.description}</p>
                        <p className="achievement-lore">{achievement.lore_description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Non hai ancora sbloccato nessun obiettivo.</p>
              )}
            </div>

            {/* Sezione Contributi */}
            <div className="user-contributions">
              <h3>Contributi</h3>
              {userRectangles.length > 0 ? (
                userRectangles
                  .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                  .map((rect, index) => (
                    <div key={index} className="user-rectangle">
                      <p><strong>n.{index + 1}:</strong> {new Date(rect.created_at).toLocaleString()}</p>
                    </div>
                  ))
              ) : (
                <p>Nessun contributo trovato</p>
              )}
            </div>
          </div>
        ) : (
          <p>Caricamento informazioni utente...</p>
        )}
        <button className="close-button" onClick={() => setDashModalOpen(false)}>Chiudi</button>
      </Modal>
    </>
  )};

  // Esportazioni
  export default ModalManager;
  export { handleSendVerificationEmail, handleRegister };
